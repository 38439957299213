import $ from "jquery";

function addTooltipText() {
  $('[data-toggle="tooltip"]').tooltip();
}

function addTooltip(icon, text) {
  icon.setAttribute("data-toggle", "tooltip");
  icon.setAttribute("data-html", "true");
  icon.setAttribute("data-placement", "right");
  icon.setAttribute("title", text);

  addTooltipText();
}

$(document).ready(() => {
  const textWithIcon = document.getElementsByClassName("text-with-icon-js");
  if (textWithIcon) {
    Array.from(textWithIcon).forEach((el) => {
      const element = el;
      const icon = element.querySelector(".info-point");
      if (icon) {
        const text = element.querySelector(".tooltiptext-for-icon").innerHTML;
        icon.addEventListener("mouseover", addTooltip(icon, text));
      }
    });
  }

  const calculators = document.getElementsByClassName("calculator");
  if (calculators) {
    Array.from(calculators).forEach((element) => {
      element.addEventListener("calculator:result", (e) => {
        $(e.currentTarget).find(".calculator__result_item__tooltip").tooltip();
      });
    });
  }
});
