import $ from "jquery";

function getPreviousUrl() {
  window.parent.history.pushState(
    "",
    "",
    window.localStorage.getItem("previousUrl")
  );
  const url = window.localStorage.getItem("previousUrl");
  window.history.go(url);
}

$(document).ready(() => {
  if (
    document.querySelectorAll('*[id^="product-list-react"]') ||
    document.querySelectorAll('*[id^="infobase-list-react"]')
  ) {
    window.onpagehide = function () {
      window.localStorage.setItem("previousUrl", document.URL);
    };
  }

  if (document.getElementById("back-button-js")) {
    const backButton = document.getElementById("back-button-js");
    if (document.getElementsByClassName("js-cad-download")) {
      backButton.addEventListener("click", getPreviousUrl);
    } else {
      backButton.addEventListener("click", (e) => {
        e.preventDefault();
        window.history.go(-1);
      });
    }
  }
});
