import $ from "jquery";
import { scrollToId } from "./anchor";

function openAccordionFromSession() {
  const lastSelectedUrl = sessionStorage.getItem("url");
  const currentUrl = window.location.href;
  if (lastSelectedUrl === currentUrl) {
    const idToBeOpend = document.getElementById(
      sessionStorage.getItem("accordionId")
    );
    const header = idToBeOpend.parentNode.children[0].children[0];
    idToBeOpend.classList.add("show");
    header.classList.remove("collapsed");
    $(window).scrollTop(sessionStorage.scrollPos);
  }
}

$(document).ready(() => {
  if (document.querySelector(".js-collapsible") !== null) {
    $(".js-collapsible").on("hidden.bs.collapse", () => {
      $(".js-collapsible--secondary").collapse("hide");
    });

    $(".js-collapsible").on("show.bs.collapse", function () {
      $(".js-collapsible--secondary").collapse("show");
      const accordionId = this.id;
      scrollToId(accordionId);
      const url = window.location.href;
      sessionStorage.setItem("accordionId", accordionId);
      sessionStorage.setItem("url", url);
      sessionStorage.scrollPos = $(window).scrollTop();
    });
    openAccordionFromSession();
  }
});
