import $ from "jquery";

const getDownloadLinks = (id) => $(`[data-download-confirmation="${id}"]`);

const enableDownloadLinks = (id) => {
  getDownloadLinks(id).each((idx, link) => {
    $(link).removeClass("download-confirmation-link--disabled");
    $(link).attr("href", $(link).data("download-url"));
  });
};

const disableDownloadLinks = (id) => {
  getDownloadLinks(id).each((idx, link) => {
    $(link).addClass("download-confirmation-link--disabled");
    $(link).removeAttr("href");
  });
};

$(document).ready(() => {
  $(".download-confirmation").each((idx, checkbox) => {
    const id = $(checkbox).data("download-confirmation");

    if ($(checkbox).is(":checked")) {
      enableDownloadLinks(id);
    }

    $(checkbox).on("change", () => {
      if ($(checkbox).is(":checked")) {
        enableDownloadLinks(id);
      } else {
        disableDownloadLinks(id);
      }
    });
  });
});
