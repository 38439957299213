import $ from "jquery";

function showIFrame() {
  const iFrame = this.nextElementSibling;
  iFrame.classList.remove("d-none");
}

$(document).ready(() => {
  const cadButton = document.getElementsByClassName("js-cad-download");
  Array.from(cadButton).forEach((el) => {
    const element = el;
    element.addEventListener("click", showIFrame);
  });
});
