import $ from "jquery";

function scrollToId(id) {
  const scrollElem = document.getElementById(id);
  const navBars = document.getElementsByClassName("js-navbar");
  const navBar = navBars && navBars[0];
  const navOffset = navBar ? navBar.getBoundingClientRect().height : 0;
  const extendedAccordion = document
    .getElementsByClassName("js-collapsible collapse show")
    .item(0);

  if (extendedAccordion) {
    const header = extendedAccordion.parentNode.children[0].children[0];
    header.classList.add("collapsed");
    extendedAccordion.classList.remove("show");
  }

  const parent =
    scrollElem.parentElement.parentElement.parentElement.parentElement;
  const rect = parent.getBoundingClientRect();

  if (scrollElem) {
    const offsetTop = Math.max(
      rect.top + document.documentElement.scrollTop - navOffset,
      0
    );
    window.scrollTo(0, offsetTop);
  }
}

function scrollToAccordion(klass) {
  $(klass).click(function (e) {
    e.preventDefault();
    const accordionId = this.getAttribute("href");
    const id = accordionId.replace("#", "");
    scrollToId(id);
    const accordion = document.getElementById(id);
    const openAccordion = accordion.parentElement.parentElement.parentElement;
    const openAccordionId = openAccordion.id;
    if (!$("#openAccordionId").is(":empty")) {
      $(`#${openAccordionId}`).collapse("show");
    }
  });
}

$(document).ready(() => {
  scrollToAccordion(".linkToAccordionItem");
});
