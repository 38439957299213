import $ from "jquery";
import { scrollToId } from "./anchor";

export default function scrollToAccordion(klass) {
  $(klass).click(function (e) {
    e.preventDefault();
    const accordionId = $(this).data("id");
    scrollToId(accordionId);
    $(`#${accordionId}`).collapse("show");
  });
}
