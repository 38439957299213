import $ from "jquery";

$(document).ready(() => {
  const scrollElem = document.getElementById("scroll-point-zip");

  if (scrollElem) {
    const navBars = document.getElementsByClassName("js-navbar");
    const navBar = navBars && navBars[0];
    const navOffset = navBar ? navBar.getBoundingClientRect().height : 0;
    const rect = scrollElem.getBoundingClientRect();
    const offsetTop = Math.max(
      rect.top + document.documentElement.scrollTop - navOffset - 300,
      0
    );
    window.scrollTo(0, offsetTop);
  }
});
